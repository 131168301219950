import React from 'react';
import Layout from '../../components/Layout';
import NotFound from './NotFound';
import { setCity } from '../../actions/city';
import { getCityConfig } from '../../apiUtils';

const title = 'Страница не найдена';

async function action({ params, store, fetch }) {
	const { cityConfig } = store.getState();

	const city = setCity({
		store,
		params,
		fetch,
	});

	if (!cityConfig) {
		await getCityConfig(city.cityIds, {
			store,
			fetch,
		});
	}

	return {
		chunks: ['not-found'],
		title,
		component: (
			<Layout isNotFound>
				<NotFound />
			</Layout>
		),
		status: 404,
	};
}

export default action;
